import { mapHelper } from 'common/utils'

// 状态
const isReply = [
  {
    text: '已回复',
    value: 1
  },
  {
    text: '待回复',
    value: 0
  }
]

// 评价类型
const sourceType = [
  {
    text: '系统评价',
    value: 1
  },
  {
    text: '用户评价',
    value: 0
  }
]

// 是否二次修改
const appraiseAgain = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

// 订单来源
const overbookType = [
  {
    text: '业主端app',
    value: 0
  },
  {
    text: '管理后台',
    value: 1
  },
  {
    text: '园区管理app',
    value: 2
  },
  {
    text: '支付宝',
    value: 3
  },
  {
    text: '第三方回流',
    value: 4
  }
]

// 订单来源
const orderStatus = [
  {
    text: '待接收',
    value: 1
  },
  {
    text: '处理中',
    value: 2
  },
  {
    text: '已处理',
    value: 3
  },
  {
    text: '已评价',
    value: 4
  },
  {
    text: '已关闭',
    value: 5
  },
  {
    text: '待处理',
    value: 6
  }
]

const payStatus = [
  {
    text: '未支付',
    value: 0
  },
  {
    text: '已支付',
    value: 1
  }
]
const evaluationSourceOps = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '业主评价',
    value: 0
  },
  {
    text: '系统评价',
    value: 1
  },
  {
    text: '回访评价',
    value: 2
  }
]
const evaluationSourceObj = {}
evaluationSourceOps.forEach(item => {
  evaluationSourceObj[item.value] = item.text
})
const {
  map: replyStatusMap,
  setOps: replyStatusOps
} = mapHelper.setMap(isReply)

const {
  map: sourceTypeMap,
  setOps: sourceTypeOps
} = mapHelper.setMap(sourceType)

const {
  map: appraiseAgainMap,
  setOps: appraiseAgainOps
} = mapHelper.setMap(appraiseAgain)

const {
  map: overbookTypeMap,
  setOps: overbookTypeOps
} = mapHelper.setMap(overbookType)

const {
  map: orderStatusMap,
  setOps: orderStatusOps
} = mapHelper.setMap(orderStatus)

const {
  map: payStatusMap,
  setOps: payStatusOps
} = mapHelper.setMap(payStatus)

export {
  replyStatusOps,
  replyStatusMap,
  sourceTypeOps,
  sourceTypeMap,
  appraiseAgainOps,
  appraiseAgainMap,
  overbookTypeMap,
  overbookTypeOps,
  orderStatusMap,
  orderStatusOps,
  payStatusMap,
  payStatusOps,
  evaluationSourceOps,
  evaluationSourceObj
}
